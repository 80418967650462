module.exports = [{
      plugin: require('/home/travis/build/iamdavidfrancis/df.productions/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/iamdavidfrancis/df.productions/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138206112-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/home/travis/build/iamdavidfrancis/df.productions/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
